/* src/components/Header.css */
.header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 1);
    padding: 10px 10%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    /* 헤더 높이 */
    z-index: 1000;
    /* 다른 콘텐츠 위에 표시 */
    transition: background-color 0.3s ease, border-bottom 0.3s ease;
}

.header.scrolled {
    border-bottom: 1px solid #F5EDED;
    background-color: rgba(255, 255, 255, 0.8);
}

.sidebar-menu-container hr {
    border-color: #6482AD;
    width: 150px;
}

.logo {
    display: flex;
    justify-content: center;
}

.logo img {
    width: 80px;
    height: auto;
}

.button-container {
    text-align: center;
}

.header-button {
    color: white;
    padding: 5px 20px;
    background-color: rgb(100, 130, 173);
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
}

.header-text {
    font-size: 20px;
    font-weight: bold;
}

.menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    font-size: 1rem;
}

.inactive-link {
    color: black;
}

.active-link {
    color: rgb(100, 130, 173);
}

@media (max-width: 767px) {
    .header {
        padding: 10px 5%;
        align-items: center;
    }

    .logo img {
        width: 40px;
    }

    .logo {
        /* gap: 5px; */
        align-items: flex-end;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .header {
        padding: 10px 5%;
    }
}