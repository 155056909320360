.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F5EDED;
    color: #6482AD;
    text-align: center;
    padding: 20px;
}

.not-found-container h1 {
    font-size: 120px;
    margin: 0;
    color: #7FA1C3;
}

.not-found-container h2 {
    font-size: 36px;
    margin: 0;
    margin-bottom: 20px;
}

.not-found-container p {
    font-size: 18px;
    margin-bottom: 30px;
}

.home-button {
    background-color: #6482AD;
    color: #F5EDED;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.home-button:hover {
    background-color: #7FA1C3;
}

@media (max-width: 767px) {
    .not-found-container h1 {
        font-size: 80px;
    }

    .not-found-container h2 {
        font-size: 24px;
    }

    .not-found-container p {
        font-size: 16px;
    }
}