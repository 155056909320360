.post-list {
    list-style-type: none;
    padding: 0;
}

.post-item {
    border-bottom: 1px solid #e0e0e0;
    padding: 15px 0;
}

.post-item:last-child {
    border-bottom: none;
}

.post-item a {
    text-decoration: none;
    color: inherit;
}

.post-item h3 {
    margin: 0 0 10px 0;
    font-size: 18px;
    color: #333;
}

.post-item p {
    margin: 0 0 10px 0;
    font-size: 14px;
    color: #666;
}

.post-meta {
    font-size: 12px;
    color: #999;
}

.post-meta span {
    margin-right: 10px;
}