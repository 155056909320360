.review-container {
    max-width: 600px;
    margin: 30px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

h1 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: #555;
    font-weight: 500;
}

.form-group select,
.form-group input[type="text"],
.form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    font-size: 14px;
    transition: border-color 0.3s ease;
}

.form-group select:focus,
.form-group input[type="text"]:focus,
.form-group textarea:focus {
    border-color: #4a90e2;
    outline: none;
}

.form-group textarea {
    height: 120px;
    resize: vertical;
}

.submit-button {
    background-color: #4a90e2;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s ease;
    width: 100%;
}

.submit-button:hover {
    background-color: #357abd;
}

small {
    display: block;
    margin-top: 8px;
    color: #888;
    font-size: 12px;
}

.form-group input[type="file"] {
    border: 1px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    width: 100%;
}

.form-group input[type="file"]:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.star-rating {
    display: flex;
    gap: 5px;
    font-size: 36px;
    color: #ddd;
    cursor: pointer;
}

.star {
    display: inline-block;
    transition: color 0.2s ease;
}

.star.filled {
    color: #ffd700;
}