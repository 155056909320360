.signup-container {
  display: flex;
  flex-direction: column;
  /* 버튼들을 세로로 배열합니다 */
  align-items: center;
  /* 가운데 정렬 */
  justify-content: center;
  /* 가운데 정렬 */
  min-height: 50vh;
  /* 화면 전체 높이만큼 컨테이너를 늘립니다 */
  margin-left: 10px;
  margin-right: 10px;
  min-width: 320px;
}

.already-signup-text {
  /* 글씨 색깔은 파란색으로 */
  color: #007bff;
  /* 밑줄을 추가합니다 */
  /* text-decoration: underline; */
  /* 마우스를 올리면 커서가 손가락 모양으로 바뀝니다 */
  cursor: pointer;
  /* 글씨 크기 */
  font-size: 11px;
}