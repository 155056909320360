.address_search {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.address-input-1 {
    display: flex;
    margin-bottom: .25rem;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
}

/* mb-1 flex-1 px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-800 dark:text-white */
.address-input-box {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 1px solid rgb(209, 213, 219);
    border-radius: .325rem;
    cursor: default;
    min-width: 0;
    max-width: 100%;
    flex: 1;
}

.address-btn {
    min-width: 130px;
    padding-bottom: .5rem;
    padding-top: .5rem;
    padding-left: .5rem;
    padding-right: .5rem;
    border: 1px solid #6482AD;
    border-radius: .375rem;
    cursor: pointer;
    color: white;
    background-color: #7FA1C3;
}

.input-box-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}

.done-btn {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: .375rem;
    cursor: pointer;
    border: 1px solid #6482AD;
    color: white;
    background-color: #7FA1C3;
}