.banner-container {
    width: 100%;
    max-width: 1600px;
    min-width: 300px;
    height: 200px;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    border-radius: 5px;
    /* Center the banner */
}

.custom-dots {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;

}

.custom-dots li {
    margin: 0 5px;
    padding: 0;
    float: left;
    color: transparent;
    /* 원 */
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid #007bff;
    cursor: pointer;
}

.custom-dots li.slick-active {
    background-color: #007bff;
}

.slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.image-wrapper {
    max-width: 800px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

@media (min-width: 768px) {
    .image-wrapper {
        min-width: 800px;
    }

    .image-wrapper img {
        width: 100%;  /* 이미지를 컨테이너의 너비에 맞춥니다 */
        height: 100%; /* 이미지를 컨테이너의 높이에 맞춥니다 */
        object-fit: cover; /* 이미지가 잘리지 않도록 컨테이너를 완전히 채우도록 합니다 */
    }
}

@media (max-width: 767px) {
    .image-wrapper {
        width: 100%;
        max-width: 400px;
        height: 200px;
    }
}

.banner-image {
    object-fit: contain;
}