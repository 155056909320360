.building-community-page {
    line-height: 1.6;
    color: #333;
}

.building-community-page .page-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.building-community-page .community-info {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 30px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.building-community-page .community-info:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.building-community-page .community-name {
    font-size: 28px;
    font-weight: bold;
    margin: 0 0 15px 0;
    color: #2c3e50;
    cursor: pointer;
}

.building-community-page .community-description {
    font-size: 16px;
    color: #34495e;
    margin-bottom: 15px;
    line-height: 1.8;
}

.building-community-page .community-meta {
    font-size: 14px;
    color: #7f8c8d;
    display: flex;
    align-items: center;
}

.building-community-page .community-meta::before {
    content: '👥';
    margin-right: 5px;
}

.building-community-page .join-button {
    background-color: #7FA1C3;
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    float: right;
    transition: all 0.3s ease;
}

.building-community-page .join-button:hover {
    background-color: #6482AD;
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(52, 152, 219, 0.3);
}

.building-community-page .join-button:active {
    transform: translateY(0);
}

.building-community-page .content-container {
    display: flex;
    gap: 30px;
}

.building-community-page .main-content,
.building-community-page .side-content {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 25px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.building-community-page .main-content {
    flex: 1;
}

.building-community-page .side-content {
    flex: 1;
}

.building-community-page .content-title {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    margin-bottom: 20px;
    color: #2c3e50;
    border-bottom: 2px solid #7FA1C3;
    padding-bottom: 10px;
    align-items: flex-end;
}

.content-title a {
    color: #7FA1C3;
    text-decoration: none;
    font-size: 14px;
}

@media (max-width: 768px) {
    .building-community-page .page-container {
        padding: 15px;
    }

    .building-community-page .community-info {
        padding: 20px;
    }

    .building-community-page .community-name {
        font-size: 24px;
    }

    .building-community-page .community-description {
        font-size: 14px;
    }

    .building-community-page .community-meta {
        font-size: 12px;
    }

    .building-community-page .join-button {
        width: 100%;
        margin-top: 15px;
        float: none;
    }

    .building-community-page .content-container {
        flex-direction: column;
    }

    .building-community-page .main-content,
    .building-community-page .side-content {
        margin-bottom: 20px;
    }

    .building-community-page .content-title {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .building-community-page .community-name {
        font-size: 22px;
    }

    .building-community-page .community-description {
        font-size: 13px;
    }

    .building-community-page .join-button {
        font-size: 14px;
        padding: 10px 20px;
    }

    .building-community-page .content-title {
        font-size: 18px;
    }
}

.building-community-page .leave-button {
    background-color: #9e9e9e;
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    float: right;
    transition: all 0.3s ease;
}

.building-community-page .leave-button:hover {
    background-color: #c0392b;
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(231, 76, 60, 0.3);
}

.building-community-page .leave-button:active {
    transform: translateY(0);
}

/* 모바일 반응형 스타일 수정 */
@media (max-width: 768px) {
    .building-community-page .button-container {
        flex-direction: column;
    }

    .building-community-page .button-container button {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
    }

    .building-community-page .join-button,
    .building-community-page .leave-button {
        float: none;
    }

    .quick-content-container {
        flex-direction: column;
    }

    .contour {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

/* 접근 거부 메시지 스타일 개선 */
.access-denied-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.quick-content-container {
    display: flex;
    justify-content: space-between;
}

.contour {
    border: 1px solid #7fa1c3;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 20px;
    opacity: 0.3;
}

.quick-content {
    flex: 1;
    word-break: keep-all;
}

.quick-item h3 {
    font-size: 20px;
    color: #2c3e50;
    padding-top: 5px;
}

.quick-item p {
    font-size: 12px;
    color: #34495e;
    line-height: 1.8;
    opacity: 0.8;
}

.quick-item a {
    display: flex;
    gap: 10px;
    align-items: center;
}

.quick-item:not(:last-child) {
    margin-bottom: 20px;
}