.box-frame {
    background-color: #f9f9fb;
    border-color: #e5e5e5;
    border-width: 1px;
    border-radius: 0.75rem;
    position: relative;
    width: 100%;
    /* 반응형을 위해 너비를 100%로 설정 */
    /* max-width: 400px; */
    /* 최대 너비를 설정하여 큰 화면에서도 적절히 보이도록 함 */
    height: auto;
    /* 높이를 자동으로 설정하여 내용에 따라 조정되도록 함 */
    padding: 20px;
    /* 내부 여백을 설정 */
    box-sizing: border-box;
    /* 패딩과 보더를 포함하여 박스 크기 계산 */
    margin: 0 auto;
    /* 가운데 정렬 */
    cursor: pointer;
    overflow: hidden;
}

.box-frame-text {
    z-index: 10;
    word-break: keep-all;
    white-space: pre-line;
    position: relative;
}

.box-frame-img {
    position: absolute;
    /* 부모 요소(.box-frame)를 기준으로 위치를 설정 */
    bottom: 0;
    /* 부모의 아래쪽에 고정 */
    right: 0;
    /* 부모의 오른쪽에 고정 */
    z-index: 5;
}

.box-frame-img img {
    height: 100px;
    /* 기본 높이 설정 */
    object-fit: contain;
    /* 이미지가 비율을 유지하며 부모 div 내에 맞추도록 설정 */
}

.box-frame h2 {
    margin: 0;
    /* 기본 마진 제거 */
    font-size: 1.25rem;
    /* 반응형 폰트 크기 설정 */
    font-weight: 1000;
    /* 폰트 굵기 설정 */
    color: #333;
    /* 글자 색상 설정 */
}

.box-frame p {
    margin: 0;
    /* 기본 마진 제거 */
    padding-top: 10px;
    /* 텍스트 상단에 여백 추가 */
    font-size: 1rem;
    /* 폰트 크기 설정 */
    color: #666;
    /* 글자 색상 설정 */
}

/* 반응형 설정 */
@media (max-width: 767px) {
    .box-frame {
        padding: 15px;
        /* 모바일에서 여백을 줄임 */
    }

    .box-frame-img img {
        height: 70px;
        /* 모바일에서 이미지 높이를 줄임 */
        opacity: 0.5;
    }

    .box-frame h2 {
        font-size: 1rem;
        /* 모바일에서 폰트 크기를 줄임 */
    }

    .box-frame p {
        font-size: 0.875rem;
        /* 모바일에서 폰트 크기를 줄임 */
    }
}

@media (max-width: 480px) {
    .box-frame {
        padding: 10px;
        /* 더 작은 모바일 화면에서 여백을 더욱 줄임 */
    }

    .box-frame-img img {
        height: 80px;
        /* 작은 모바일 화면에서 이미지 높이를 더욱 줄임 */
    }

    .box-frame h2 {
        font-size: 0.875rem;
        /* 작은 모바일 화면에서 폰트 크기를 줄임 */
    }

    .box-frame p {
        font-size: 0.75rem;
        /* 작은 모바일 화면에서 폰트 크기를 줄임 */
    }
}