.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
}

.modal-content h2 {
    margin-top: 0;
}

.modal-content p {
    font-size: 14px;
    color: #666;
    /* 음절로 엔터 */
    white-space: pre-line;
    word-break: keep-all;
    margin-left: 10px;
}

.modal-content input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
}

.modal-buttons button {
    margin-left: 10px;
    padding: 5px 10px;
}