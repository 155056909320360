.post-detail-container {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.post-detail-header {
    margin-bottom: 2rem;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1rem;
}

.post-detail-content img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    margin: 1rem 0;
}

.post-detail-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
}

.post-detail-info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: #666;
    font-size: 0.9rem;
}

.post-detail-date {
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
}

.post-detail-items {
    display: flex;
    flex-direction: 'row';
    align-items: center;
    padding-left: 0.6rem;
    gap: 12px;
}

.post-detail-content {
    line-height: 1.6;
    color: #444;
    margin-bottom: 2rem;
}

.post-navigation {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
    padding: 1rem 0;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}

.nav-link {
    text-decoration: none;
    color: #6482AD;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
}

.nav-link:hover {
    color: #7FA1C3;
}

.nav-link svg {
    margin: 0 5px;
}

.nearby-posts {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 1.5rem;
    margin: 2rem 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nearby-posts h3 {
    font-size: 1.2rem;
    color: #333;
    margin-top: 0;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #6482AD;
}

.nearby-posts ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.nearby-posts li {
    border-bottom: 1px solid #e0e0e0;
    transition: background-color 0.3s ease;
}

.nearby-posts li:last-child {
    border-bottom: none;
}

.nearby-posts li:hover {
    background-color: #f0f0f0;
}

.nearby-posts a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem;
    text-decoration: none;
    color: #333;
}

.nearby-posts .post-date {
    font-size: 0.8rem;
    color: #666;
    white-space: nowrap;
}

.nearby-posts li.current-post {
    background-color: #e6f0ff;
    border-left: 3px solid #6482AD;
}

.nearby-posts li.current-post a {
    font-weight: bold;
    color: #6482AD;
    padding-left: 0.5rem;
}

.post-detail-comments {
    margin-top: 2rem;
    border-top: 1px solid #e0e0e0;
    padding-top: 1rem;
}

.comments-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
}

.new-comment-form {
    margin-bottom: 2rem;
}

.new-comment-form.disabled,
.reply-form.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.new-comment-form textarea,
.reply-form textarea {
    width: 100%;
    min-height: 100px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.new-comment-form button,
.reply-form button {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #6482AD;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.new-comment-form button:hover,
.reply-form button:hover {
    background-color: #7FA1C3;
}

.comment {
    background-color: #f9f9f9;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
}

.comment.reply {
    margin-left: 2rem;
    background-color: #f0f0f0;
    border-left: 3px solid #6482AD;
}

.comment-content {
    color: #444;
    margin-bottom: 0.5rem;
}

.comment-info {
    font-size: 0.8rem;
    color: #666;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comment-reply-info button {
    background-color: transparent;
    border: none;
    color: #6482AD;
    cursor: pointer;
    font-size: 0.9rem;
    padding: 0;
    transition: color 0.3s ease;
}

.comment-reply-info button:hover {
    color: #7FA1C3;
    text-decoration: underline;
}

.no-comments {
    color: #666;
    font-style: italic;
    text-align: center;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 4px;
}

.post-detail-loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #6482AD;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: post-detail-spin 1s linear infinite;
    margin: 2rem auto;
}

@keyframes post-detail-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.post-detail-error-message {
    text-align: center;
    color: #e74c3c;
    font-size: 1.2rem;
    margin-top: 2rem;
}

.post-detail-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    gap: 1rem;
}

.post-detail-actions button {
    padding: 0.5rem 1rem;
    background-color: #6482AD;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
}

.post-detail-like-button {
    background-color: #E2DAD6 !important;
    color: grey !important;
}

.post-detail-like-button.liked {
    background-color: #6482AD !important;
    color: white !important;
}

.post-detail-actions button:hover {
    background-color: #7FA1C3 !important;
    color: white !important;
}

.nearby-post-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
}


@media (max-width: 767px) {

    .post-detail-container {
        max-width: 100%;
        margin: 0;
        padding: 0;
        box-shadow: none;
        border-radius: 0;
    }

    .nearby-post-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }

    .nav-link.prev {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 50%;
        display: block;
    }

    .nav-link.next {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 50%;
        display: block;
    }

    .post-detail-actions button {
        padding: 0.5rem 1rem;
        font-size: 0.75rem;
    }
    
}