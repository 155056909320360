table {
    width: 100%;
    border-collapse: collapse;
}

table, th, td {
    border: none;
}

tr:first-child {
    border-top: 1px solid lightgray;
}

tr {
    border-bottom: 1px solid lightgray;
}

td:first-child {
    background-color: #f0f0f0;
    padding: 8px;
    font-weight: bold;
}

td:nth-child(2) {
    background-color: #ffffff;
    padding: 8px;
}

.building-page {
    padding-left: 10px;
    padding-right: 10px;
}

.building-page h1 {
    text-align: start;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.building-page .divider {
    border-bottom: 1px solid lightgray;
    margin: 10px 10px 30px 10px;
}

.building-page table {
    width: 100%;
    font-size: 0.8rem;
}

.building-page .map-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px 0 30px 0;
}

.building-page .map-wrapper {
    border: 1px solid black;
    width: 100%;
}

.box-container {
    display: flex;
    gap: 30px;
    margin: 30px 0 30px 0;
}

@media(max-width: 767px) {
    .box-container {
        gap: 10px;
    }
}

.building-review {
    margin-top: 30px;
}

.building-review h1 {
    font-size: 1.1rem;
    margin-bottom: 20px;
}

.review-item {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.review-item.reply {
    background-color: #f0f0f0;
    margin-left: 30px;
    margin-bottom: 10px;
    border-left: 3px solid #007bff;
}

.author-badge {
    display: inline-block;
    background-color: #007bff;
    color: white !important;
    font-size: 0.7rem !important;
    padding: 2px 6px;
    border-radius: 10px;
    margin-left: 8px;
    vertical-align: middle;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.reply-container {
    margin-top: -5px;
    margin-bottom: 15px;
}

.review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.review-title {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    flex-wrap: wrap;
}

/* .review-title h3 {
    font-size: 1rem;
    margin: 0;
} */

.review-title h3 {
    margin-right: 8px;
}

.review-title span {
    font-size: 0.8rem;
    color: #666;
}

.review-rating {
    background-color: #007bff;
    color: white;
    padding: 3px 8px;
    border-radius: 15px;
    font-weight: bold;
    font-size: 0.8rem;
    min-width: 40px;
}

.review-content p {
    font-size: 0.9rem;
    line-height: 1.5;
    color: #333;
    margin: 0;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.loader {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.reply-toggle {
    cursor: pointer;
    color: #007bff;
    margin-top: 10px;
    font-size: 0.9rem;
    user-select: none;
}

.replies-container {
    margin-top: 10px;
    margin-left: 20px;
    border-left: 2px solid #e0e0e0;
    padding-left: 10px;
}

.review-actions {
    margin-top: 10px;
    user-select: none;
}

.review-actions button {
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


.review-actions button.liked {
    background-color: #007bff;
    color: white;
}

.like-button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border: none;
    border-radius: 20px;
    background-color: #f0f0f0;
    color: #333;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.like-button:hover {
    background-color: #e0e0e0;
}

.like-button.liked {
    background-color: #3498db;
    color: white;
}

.thumb-icon {
    margin-right: 8px;
}

.like-count {
    font-weight: normal;
}

.review-addition {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}

.reply-input-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    margin-left: 30px;
}

.reply-input-container textarea {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    resize: vertical; /* 수직 리사이즈만 허용 */
}

.reply-input-container button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap; /* 텍스트 줄바꿈 방지 */
    display: inline-block; /* 기본 너비에 맞추기 */
}

.review-item.gold {
    /* 추천수가 많은 리뷰에 대한 효과 */
    /* 테두리가 금색이며 빛나는 애니메이션 */
    border: 1px solid f0f0f0;
    box-shadow: 0 0 10px f0f0f0;
    animation: shine 1s infinite;
} 

@keyframes shine {
    0% { box-shadow: 0 0 10px gold; }
    50% { box-shadow: 0 0 20px gold; }
    100% { box-shadow: 0 0 10px gold; }
}

.reply-input-container.disabled {
    /* 입력 불가 상태 */
    pointer-events: none;
    /* 드래그 불가 */
    user-select: none;
}

.more-reviews {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.more-reviews button {
    color: #007bff;
    cursor: pointer;
}