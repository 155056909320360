.map-layout {
    position: relative;
}

.map {
    width: 100%;
    height: 60vh;
    /* 지도 크기 설정 */
}


.popup {
    background: white;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
    /* 둥근 모서리 설정 */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    pointer-events: auto;
    /* 클릭 가능하게 설정 */
}

.popup::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

.popup button {
    margin-top: 5px;
    cursor: pointer;
}

.loading-overlay {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    z-index: 1000;
}