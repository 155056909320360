.footer-container {
    flex: 1;
    flex-direction: column;
    min-height: 200px;
    background-color: #F5EDED;
    color: gray;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    /* 이 부분은 그대로 유지 */
    margin-top: 20px;
    padding: 10px 20px;
}

.footer-content {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    margin-top: 5px;
}

.footer-copyright {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-top: 5px;
}

.footer-content a {
    text-decoration: none;
    color: gray;
    transition: text-decoration 0.2s ease-in-out;
}

.footer-copyright a {
    text-decoration: none;
    color: gray;
    transition: text-decoration 0.2s ease-in-out;
}

.footer-content a:hover {
    text-decoration: underline;
}

.footer-content span {
    margin: 0 10px;
    display: flex;
    align-items: center;
    /* 스팬과 텍스트 수직 가운데 정렬 */
}

.footer-copyright span {
    margin: 0 10px;
    display: flex;
    align-items: center;
    /* 스팬과 텍스트 수직 가운데 정렬 */
}

.tab-space {
    display: inline-block;
    width: 2em; /* 원하는 공백 크기 조절 */
}

.footer-auth {
    display: flex;
    flex-direction: row;
}

.footer-links {
    display: flex;
    flex-direction: row;
}

/* 모바일 환경에서 가운데 정렬 */
@media (max-width: 767px) {
    .footer-container {
        align-items: center;
        justify-content: flex-start;
    }
    .footer-content {
        flex-direction: column;
        text-wrap: nowrap;
        text-align: center;
        align-items: center;
    }
}