.board-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
}

.board-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.board-header h1 {
    font-size: 32px;
    font-weight: 500;
    color: #2c3e50;
}

.write-button {
    background-color: #6482AD;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.write-button:hover {
    background-color: #7FA1C3;
}

.search-form {
    display: flex;
    margin-bottom: 40px;
}

.search-input {
    flex-grow: 1;
    padding: 12px 20px;
    border: 1px solid #7FA1C3;
    border-radius: 4px 0 0 4px;
    font-size: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.search-button {
    background-color: #7FA1C3;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s ease;
}

.search-button:hover {
    background-color: #6482AD;
}

.post-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.post-card {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    text-decoration: none;
    color: #2c3e50;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.post-card:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

.post-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    color: #2c3e50;
}

.post-meta {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #6482AD;
}

.post-meta-items {
    display: flex;
    align-items: center;
    gap: 10px;
}

.loading,
.no-posts {
    text-align: center;
    padding: 60px 0;
    font-size: 18px;
    color: #2c3e50;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto 20px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.pagination-button {
    background-color: #E2DAD6;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 16px;
}

.pagination-button:hover:not(:disabled),
.pagination-button.active {
    background-color: #F5EDED;
    color: black;
}

.pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .board-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .write-button {
        margin-top: 20px;
    }

    .search-form {
        flex-direction: column;
    }

    .search-input,
    .search-button {
        width: 100%;
        border-radius: 4px;
    }

    .search-button {
        margin-top: 10px;
    }

    .post-grid {
        grid-template-columns: 1fr;
    }

    .post-meta {
        flex-direction: column-reverse
    }
}