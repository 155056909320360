.box-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.cbox-container {
    display: flex;
    flex-direction: row;
    gap: 150px;
}

.text-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    white-space: pre-line;
    word-break: keep-all;
}

.img-container {
    display: flex;
    z-index: 4;
}

@media(max-width: 767px) {
    .img-container {
        position: absolute;
        max-width: 300px;
        opacity: 0.2;
    }

    .cbox-left-container {
        margin: 0px 0px 0px 0px !important;
    }

    .cbox-container {
        align-items: center;
        justify-content: center;
    }
}

.cbox-left-container {
    display: flex;
    flex-direction: column;
    gap: 100px;
    margin: 0 20px 0 20px;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 5;
}

.cbox-btn {
    min-width: 300px;
}

.cbox-btn p {
    color: #6482AD;
    animation: bounce 2s infinite; /* 애니메이션 이름, 지속 시간, 반복 횟수 */
    display: inline-block; /* 애니메이션 효과를 위한 inline-block 설정 */
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0); /* 기본 위치 */
    }
    40% {
        transform: translateY(-20px); /* 위로 튀어오르는 위치 */
    }
    60% {
        transform: translateY(-10px); /* 아래로 떨어지기 전 위치 */
    }
}

.cbox-h1 {
    font-size: 32px;
    font-weight: bold;
}

.cbox-span {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight:100;
    color: gray;
}