.dropdown-container {
    user-select: none;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 10%;
    background-color: #F5EDED; /* 배경색 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
    padding: 10px 0;
    border-radius: 4px; /* 모서리 둥글게 */
    z-index: 1000; /* 다른 요소들보다 위에 표시 */
    min-width: 160px;
    user-select: none;
}

.dropdown-menu::after {
    content: "";
    position: absolute;
    top: -20px; /* 화살표 위치 조정 */
    right: 4px; /* 오른쪽 끝에서 약간 떨어지게 설정 */
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #F5EDED transparent; /* 화살표 색상과 방향 설정 */
}

@media (max-width: 767px) {
    .dropdown-menu {
        /* top 속성 삭제 */
        top: unset;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .dropdown-menu {
        right: 5%;
    }
}

/* 드롭다운 메뉴에서 드롭다운 아이템 스타일링 */
.dropdown-menu li {
    padding: 8px 16px;
    color: #333;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* 드롭다운 아이템 호버 효과 */
.dropdown-menu li:hover {
    background-color: #E2DAD6;
}