.postmodal-overlay {
    background: rgba(0, 0, 0, 0.25);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-width: 1px;
}

.postmodal {
    border-width: 1px;
    border-radius: 10px;
}