.create-post-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.create-post-page h1 {
    text-align: center;
    margin-bottom: 20px;
}

.create-post-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.form-group textarea {
    height: 200px;
    resize: vertical;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.form-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.form-actions button[type="submit"] {
    background-color: #4CAF50;
    color: white;
}

.form-actions button[type="submit"]:hover {
    background-color: #45a049;
}

.form-actions button[type="button"] {
    background-color: #f44336;
    color: white;
}

.form-actions button[type="button"]:hover {
    background-color: #da190b;
}

.form-actions button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.ql-container {
    min-height: 200px;
    font-size: 16px;
}

.ql-editor {
    min-height: 200px;
}

@media (max-width: 768px) {
    .create-post-page {
        padding: 10px;
    }

    .create-post-form {
        padding: 10px;
    }

    .form-group input,
    .form-group textarea {
        padding: 5px;
        font-size: 14px;
    }

    .form-actions button {
        padding: 5px 10px;
        font-size: 14px;
    }

    .ql-container {
        min-height: 100px;
        font-size: 14px;
    }

    .ql-editor {
        min-height: 100px;
    }
}