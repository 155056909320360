@keyframes slideIn {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.slide-in {
    animation: slideIn 0.5s ease-out forwards;
}

@keyframes slideOut {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.slide-out {
    animation: slideOut 0.5s ease-out forwards;
}