.recent-searches {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.recent-searches h3 {
    color: #333;
    margin-bottom: 15px;
    font-size: 1.2rem;
    border-bottom: 2px solid #e9ecef;
    padding-bottom: 10px;
}

.recent-search-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.recent-search-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e9ecef;
}

.recent-search-item:last-child {
    border-bottom: none;
}

.recent-search-link {
    color: #6482AD;
    text-decoration: none;
    margin-right: 10px;
}

.recent-search-link:hover {
    text-decoration: underline;
}

.remove-search-button {
    background: none;
    border: none;
    color: #dc3545;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 0 5px;
}

.remove-search-button:hover {
    color: #c82333;
}

.no-recent-searches {
    color: #6c757d;
    text-align: center;
    padding: 10px 0;
}