/* App.css */
.map-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .map {
    width: 100%;
    height: 60vh;
  }
  
  .info-box {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  