.sidebar-background {
    background-color: #202020;
    opacity: 0.5;
    width: 100%;
    position: fixed;
}

.sidebar-container {
    position: fixed;
    z-index: 100;
}

.sidebar {
    background-color: #F5EDED;
    border-left: 4px solid #7fa1c37e;
    position: fixed;
    /* 사이드바를 화면에 고정 */
    top: 0;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
    /* 사이드바가 처음에는 화면 밖에 위치하도록 설정 */
    transition: transform 0.4s ease;
    /* 사이드바의 애니메이션 설정 */
    width: 280px;
    /* 사이드바의 너비 설정 */
    z-index: 50;
    /* 사이드바의 z-index는 헤더보다 낮게 설정 */
    border-top-left-radius: 15px;
    /* 상단 왼쪽 모서리를 둥글게 */
    border-bottom-left-radius: 15px;
    /* 하단 왼쪽 모서리를 둥글게 */
}

.sidebar-overlay {
    position: fixed;
    /* 오버레이를 화면에 고정 */
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* 오버레이의 배경색 설정 */
    z-index: 40;
    /* 오버레이의 z-index는 사이드바보다 낮게 설정 */
    display: none;
    /* 오버레이를 처음에는 화면에 표시하지 않도록 설정 */
}

.sidebar.open {
    transform: translateX(0);
    /* 사이드바가 화면에 나타나도록 설정 */
}

.sidebar-overlay.open {
    display: block;
    /* 오버레이가 화면에 표시되도록 설정 */
}

.sidebar-button {
    position: absolute;
    /* 수정: 버튼의 위치를 사이드바 내로 조정 */
    top: 10px;
    left: 10px;
    /* 사이드바 내에서 버튼 위치 조정 */
    width: 40px;
    height: 40px;
    z-index: 100;
    /* 사이드바 버튼이 사이드바 위에 위치하도록 설정 */
    transition: transform 0.8s ease;
    /* 버튼의 전환 효과 설정 */
    overflow: hidden;
}

.sidebar-content {
    padding-top: 100px;
    padding-left: 30px;
    padding-bottom: 50px;
    position: relative;
    width: 100%;
    display: flex;
}

.sidebar-menu-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sidebar-menu {
    display: flex;
    flex-direction: column;
    gap: 15px;
}